<template>
  <div style="background: #f6f6f6;color: #000;">
    <van-nav-bar title="收入审核(出纳专用)" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div class="nav-bar">
      <van-row class="topss">
        <van-icon @click="timeshang" name="play" style="transform: rotate(180deg)" />
        <span @click="timeshow=true">{{bizDate}}</span>
        <van-icon @click="timexia" name="play" />
      </van-row>
      <van-row class="mian_row" style="font-weight: bold;">
        <van-col span="4">日期</van-col>
        <van-col span="4">总工单数</van-col>
        <van-col span="5">已审核</van-col>
        <van-col span="5">待审核</van-col>
        <van-col span="6" style="text-align: right;padding-right: 20px;">待审金额</van-col>
      </van-row>
    </div>
    <div class="pt" v-if="list.length > 0">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads"
        :offset="10">
        <div class="mian" v-for="(item, idx) in list" :key="idx">
          <van-row class="mian_row" @click="tourls(item)">
            <van-col span="4">{{ item.bizDate?item.bizDate.substring(5, 10):'' }}</van-col>
            <van-col span="4">{{ item.total }}</van-col>
            <van-col span="5">{{ item.examineTotal }}</van-col>
            <van-col span="5">{{ item.unExamineTotal }}</van-col>
            <van-col span="5">{{ item.unExamineAmount }}</van-col>
            <van-col span="1"><van-icon name="arrow" /></van-col>
          </van-row>
        </div>
      </van-list>
    </div>
    <van-empty v-else description="暂无数据~" />

    <van-popup :close-on-click-overlay="false" v-model="timeshow" position="bottom" :style="{ height: '45%' }">
      <van-datetime-picker @cancel="timeshow = false" @confirm="settiem" v-model="currentDate" type="year-month"
        title="请选择日期" :formatter="formatter" />
    </van-popup>
  </div>
</template>

<script>
import { examinelist } from "@/api/check";
import moment from "moment";
export default {
  data() {
    return {
      list: [],
      gid: this.$route.query.gid,
      key: "",
      page: 0,
      size: 20,
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
      currentDate: new Date(),
      bizDate:moment(new Date()).format("YYYY-MM"),
      timeshow:false
    };
  },
  methods: {
    tourls(item) {
      if(!item.bizDate)return
      this.$router.push({
        path: "/examineDetails",
        query: {
          gid: this.gid,
          bizDate: item.bizDate.substring(0,10),
        },
      });
    },
    onLoads() {
      this.page++;
      this.getlist();
    },

    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    timeshang() {
      this.page = 0;
      this.total = 0;
      this.list = [];
      this.bizDate = moment(this.bizDate)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM");
      this.getlist();
    },
    timexia() {
      this.page = 0;
      this.total = 0;
      this.list = [];
      this.bizDate = moment(this.bizDate)
        .startOf("month")
        .subtract("months", -1)
        .format("YYYY-MM");
      this.getlist();
    },

    settiem(e) {
      this.bizDate = moment(e).format("YYYY-MM");
      this.page = 0;
      this.total = 0;
      this.list = [];
      this.getlist();
      this.timeshow = false;
    },
    getlist() {
      const loading = this.$toast.loading({
        overlay: true,
        duration: 0,
        message: "加载中...",
      });
      var data = {
        gid: this.gid,
        // page: this.page,
        // size: this.size,
        bizDate:this.bizDate
      };
      examinelist(data).then((e) => {
        loading.clear();
        if (e.code == 200) {
          let rows = e.data;
          this.loading = false;
          this.total = e.data.totalElements || rows.length;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        }
      });
    },

    back() {
      window.androidjs.onCallbackIntent("Finish");
      // this.$router.go(-1);
    },

    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      try {
        if (this.isiOS) {
        this.isok = false;
        this.setupWebViewJavascriptBridge((bridge) => {
          var token = bridge.callHandler(
            "getToken",
            null,
            function responseCallback(responseData) {
              localStorage.setItem("Authorization", responseData);
              _this.token = responseData;
              _this.getlist();
              return responseData;
            }
          );
        });
        console.log("ios");
      } else {
        this.isok = true;
        _this.token = window.androidjs.getLocalData("getToken");
        localStorage.setItem("Authorization", _this.token);
        _this.getlist();
        console.log("andriod");
      }
      } catch (error) {
        _this.token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNDk3IiwiZXhwIjoxNzQ0MDEyMDAxfQ.YiArmPkaLrIbCYmU_hLnZhcyqfWaAKjg5AhCoIOjMAtjWrKhMD9ROe96-eh7xeXrwUbQqJAx_HaAx15MptiIIw";
        localStorage.setItem("Authorization", _this.token);
        _this.getlist();
        console.log("token====",_this.token);
      }
    },
  },
  created() {
    // this.getlist();
    //判断是否在微信环境
    this.appSource();
  },
  mounted() { },
  computed: {},
};
</script>
<style>
body {
  background: #f6f6f6;
}
</style>
<style lang="less" scoped>
.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.topss {
  line-height: 35px;
  font-size: 17px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  color: #666;
}

.red {
  color: red;
  font-weight: bold;
}

.hedt {
  line-height: 35px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  // padding-top: 46px;
}

.flx {
  display: flex;
  align-items: center;
}

.pt {
  padding-top: 46px;
}

.mian {
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  padding: 5px 0;
  background: #fff;
}

.mian_row {
  line-height: 35px;
  font-size: 14px;

  padding: 0 10px;
  background: #fff;
}

.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;

  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}
.van-col{
  text-align: center;
}
</style>